import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EMEAChartCard from './EMEA_Common/EMEAChartCard';
import EMEAInnerChartCard from './EMEA_Common/EMEAInnerChartCard';
import { useStyles } from './EMEA_Common/EMEAKPIsWrapper';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { formatLocaleNumber } from 'helpers/emeaHelpers';
import EmeaTopMaterialSKUTable from './EmeaTopMaterialSKUTable';

const useInvoiceStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5),
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 12px 20px',
    marginTop: theme.spacing(0),
    height: theme.spacing(40),
    flexBasis: '75%'
  },
  noData: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '12vw',
    maxHeight: theme.spacing(15.25),
  },
  tableContainer: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    height: theme.spacing(32.25),
    maxHeight: theme.spacing(32.25)
  },
  hyperLink: {
    width: 'fit-content',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette?.link?.primary
  }
}));


export default function EMEAInvoiceKPIs() {
  const classes = useStyles();
  const classesInvoice = useInvoiceStyles();
  const { t } = useTranslation();
  const [params] = useSetQuery();
  const setMultiParams = useMultiSetQuery();

  const [currUnit, setCurrUnit] = useState(() => localStorage.getItem("currency") || "USD");
  const { data } = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_INVOICES_PER_DAY"] || {});
  const [invoicesTotal, setInvoicesTotal] = useState(0);

  const tableTop5CustomersStyles = {
    root : classesInvoice.root,
    noData : classesInvoice.noData,
    tableContainer : classesInvoice.tableContainer,
    hyperLink:classesInvoice.hyperLink
  }

  const additionalFilter = {
    orderType: "ORDER_ENTRY"
  }

  const setTreemapDetails = useCallback((filters) => {
    setMultiParams({
      emeaFilters: JSON.stringify(filters),
      isHyperlink: true
    });
  }, [params]);

  const setDetails = useCallback(({ key, data }) => {
    let payload = data.state;

    setMultiParams({
      emeaFilters: JSON.stringify({
        [key]: [payload]
      }),
      isHyperlink: true
    });
  }, [params]);

  const tooltipEventPlantReason = ({ data }) => {
    return `${data?.stateDesc} : ${formatLocaleNumber(data?.totalCount)} ${currUnit}`
  }

  const chartsLarge = [
    {
      title: `${t("invoices_per_day")} - ${t("cases_niv")}`,
      subtype: "SUBTYPE_EMEA_INVOICES_PER_DAY",
      chart: "trendline",
      yLabel: t('order_value_currency', { currency: currUnit }),
      colorBy: "xKey",
      colorPalette: "set12",
      trendline: false,
      showTotal: false,
      unitOfTotal: currUnit,
      defaultColor: "",
      legend: false,
      xKey: "state",
      displayKey: "state",
      tooltipKey: "totalCount",
      showXAxisLabel: false,
      maxWidth: 920,
      minWidth: 920,
      showBarLabel: false,
      tooltipOptions: {
        showTotal: true
      },
      totalCount: invoicesTotal
      // setDetails: (data) => {
      //     setDetailsRR({
      //         key: "itemReasonCodeList",
      //         data,
      //         type: 'array'
      //     })
      // },
      // isHyperlinkTooltip: true
    },
    {
      title: `${t("invoices_distribution")} - ${t("product_structure")}`,
      subtype: "SUBTYPE_EMEA_INVOICES_TOP_FIVE_PRODUCT_STRUCTURE",
      chart: "treemap",
      yLabel: t('order_value_currency', { currency: currUnit }),
      legend: true,
      colorBy: "xKey",
      displayKey: "stateDesc",
      tooltipKey: "stateDesc",
      showXAxisLabel: false,
      xKey: "stateDesc",
      yKey: "totalCount",
      totalCount: false,
      updateDesc: false,
      setDetails: (data) => {
        setTreemapDetails({
          materialHierarchyDesc: data
        })
      }
    },
    {
      title: `${t("invoices_distribution")} - ${t("sales_structure")}`,
      subtype: "SUBTYPE_EMEA_INVOICES_BY_COUNTRY_ID",
      chart: "treemap",
      yLabel: t('order_value_currency', { currency: currUnit }),
      legend: true,
      colorBy: "xKey",
      displayKey: "stateDesc",
      tooltipKey: "state",
      showXAxisLabel: false,
      xKey: "state",
      yKey: "totalCount",
      totalCount: false,
      setDetails: (data) => {
        setTreemapDetails({
          countryIdList: [data],
        })
      }
    }
  ]

  const charts = [{
    title: "top_five_invoices_per_plant",
    subtype: "SUBTYPE_EMEA_INVOICES_TOP_FIVE_PLANT",
    chart: "pie",
    tooltipKey: "state",
    displayKey: "stateDesc",
    tooltipExclusion: ["other"],
    setDetails: (data) => {
        setDetails({ key: "originSites", data })
    },
    tooltipEvent: tooltipEventPlantReason,
    testId: "top-invoices-per-plant-chart",
    refreshIcon: false
  }]

  useEffect(() => {
    setCurrUnit(localStorage.getItem("currency") || "USD");
  }, [params.currency]);

  useEffect(() => {
    if (data) {
      setInvoicesTotal(data.reduce((a, b) => a + b.totalCount, 0));
    }
  }, [data]);

  return (
    <Card className={classes.root} data-testid="invoice-kpis-wrapper">
      <CardHeader 
        title={<Typography className={classes.title} variant="h5"> {t("live_status_text")} - {t("invoicing_information")} </Typography>}
      />
      <CardContent className={classes.cardcontent}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {
            chartsLarge.map((d) => (
              <Grid item key={d.subtype} p={1} >
                <Card className={classes.chartCard1} borderRadius={32}>
                  <CardHeader
                    title={ <Typography variant="body1"> {d.title} </Typography> }
                    subheader={d.totalCount ? <Typography variant="body2"> 
                      {t("total_of", { total: formatLocaleNumber(d.totalCount), currency: currUnit })}
                    </Typography> : null}
                  />
                  <EMEAInnerChartCard {...d} excludedFilters={additionalFilter} />
                </Card>
              </Grid>
            ))
          }
        </Grid>
        <Grid container spacing={3} alignItems="center" justifyContent='space-around' wrap="nowrap" >
          {charts.map((d) => (
            <Grid item key={d.subtype} p={1}>
              <EMEAChartCard {...d} excludedFilters={additionalFilter} />
            </Grid>
          ))}
          <EmeaTopMaterialSKUTable
            styles={tableTop5CustomersStyles} 
            subType={"SUBTYPE_EMEA_INVOICES_TOP_FIVE_CUSTOMERS"}
            title={t('top5_customers')}
            tableHeadTitle1={t('ship_to')}
            tableHeadTitle2={t('invoice_value')}
            tableFooter = {"false"}
            keyName = {"stateDesc"}
            hyperlinkKey = {"corporateCustomerName"}
            hyperlinkType = {"string"}
          >
          </EmeaTopMaterialSKUTable>
        </Grid>
      </CardContent>
    </Card>
  )

}  