import React, { useEffect, createRef } from 'react';
import EMEABreadcrumb from 'components/EMEA/EMEA_Common/EMEABreadcrumb';
import EMEAInvoiceStatus from 'components/EMEA/EMEAInvoiceStatus';
import EMEAInvoiceKPIs from 'components/EMEA/EMEAInvoiceKPIs';
import EmeaTable from 'components/EMEA/EMEA_Common/EmeaTable';
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { defaultDocumentDate } from "configs/appConstants";
import { endpoints } from 'helpers';
import { emeaInvoiceSummaryColumns } from 'reduxLib/constdata/EMEA/emeaInvoiceSummaryColumns';
import emeaInvoiceSummaryFilters from 'reduxLib/constdata/EMEA/emeaInvoiceSummaryFilters';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';

const EMEAInvoices = () => {
  const setMultiQuery = useMultiSetQuery();
  const [params] = useSetQuery();
  const tableRef = createRef();

  const { documentDate } = useEMEAGlobalContext();

  const exclusionArray = ["searchStringList", "documentDateCustom", "excludeSoldToNumList", "excludeMaterialNumberList",
    "excludeDocumentTypesList", "excludeOrderReasonCodeList", "excludeItemReasonCodeList"
  ];

  const orderBy = [{
    "key": "invoiceCreatedDate",
    "ordering": "desc"
  }];

  const filterBody = {
    ...emeaInvoiceSummaryFilters,
    itemStatusBucketList: {
      type: "checkbox",
      name: "status",
      data: [
        { name: "invoiced", value: "INVOICED", checked: true } // Defaulted true
      ]
    },
    invoiceDate: {
      type: "customdaterange",
      name: "invoice_date",
      data: documentDate
    }
  }

  useEffect(() => {
    if (params.loadView) {
      setMultiQuery({
        documentDate: JSON.stringify(
          params.loadView ? defaultDocumentDate : params.documentDate
        ),
        loadView: undefined
      });
    }
  }, [params.loadView, params.documentDate]);

  return (
    <>
      <EMEABreadcrumb pageName={'invoices'} />
      <EMEAInvoiceStatus pageName={'invoices'} />
      <EMEAInvoiceKPIs />
      <EmeaTable
        fetchEndPoint={endpoints.emea.emeaTable}
        columns={emeaInvoiceSummaryColumns}
        defaultColumns={emeaInvoiceSummaryColumns}
        tableName={"invoiceSummary"}
        subType={"EMEA_OPEN_ORDERS"}
        orderType={"ORDER_ENTRY"}
        orderBy={orderBy}
        tableRef={tableRef}
        filterBody={filterBody}
        filterType={"emeaInvoiceSummaryFilters"}
        filterExclusionArray={exclusionArray}
        searchText={'search_helper_text_returns_page'}
        fixedColumn={[]}
      />
    </>
  )
}

export default EMEAInvoices;